import React, { useEffect, useState } from 'react';
import pairsRow1Config from '../config/pairsRow1.json';
import pairsRow2Config from '../config/pairsRow2.json';
import pairsRow3Config from '../config/pairsRow3.json';
import pairsRow4Config from '../config/pairsRow4.json';
import pairsRow5Config from '../config/pairsRow5.json'; // Import the new config
import Carousel from '../components/Carousel';
import './LandingPage.css';

const LandingPage = () => {
  const [tokensData, setTokensData] = useState({
    trending: [],
    artifactss: [],
    agents: [],
    memes: [],
    kails: [] // Add kails to the state
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchMarketCaps = async (pairsConfig) => {
    try {
      const chainId = 'solana'; // Assuming Solana is the target chain
      const pairIds = pairsConfig.pairs.map((pair) => pair.pairId);

      const promises = pairIds.map((pairId) =>
        fetch(`https://api.dexscreener.com/latest/dex/pairs/${chainId}/${pairId}`).then(
          (response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          }
        )
      );

      const results = await Promise.all(promises);

      const tokensWithMarketCap = results.map((data) => {
        const pair = data.pair;
        return {
          name: pair.baseToken.name,
          symbol: pair.baseToken.symbol,
          marketCap: parseFloat(pair.fdv),
          priceUsd: parseFloat(pair.priceUsd),
          liquidityUsd: parseFloat(pair.liquidity.usd),
          volume: parseFloat(pair.volume.h24),
          imageUrl: pair.info.imageUrl,  // Get the image URL from the API data
          url: pair.url,  // Link to the pair
        };
      });

      // Sort tokens by market cap in descending order and return top 10
      return tokensWithMarketCap.sort((a, b) => b.marketCap - a.marketCap).slice(0, 10);
    } catch (error) {
      console.error('Error fetching market cap data:', error);
      throw error; // Re-throw the error to be caught in the calling function
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      setError(false);
      try {
        const [trending, artifactss, agents, memes, kails] = await Promise.all([
          fetchMarketCaps(pairsRow1Config), // Ensure this fetches the updated pairsRow1Config
          fetchMarketCaps(pairsRow2Config),
          fetchMarketCaps(pairsRow3Config),
          fetchMarketCaps(pairsRow4Config),
          fetchMarketCaps(pairsRow5Config),
        ]);
        setTokensData({ trending, artifactss, agents, memes, kails });
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  if (loading) {
    return (
      <div className="landing-page">
        <h1>aifolio</h1>
        <h2>swarm intelligence index for sentient memes</h2>
        <h2>$aifolio</h2>
        <div className="loader">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="landing-page">
        <h1>aifolio</h1>
        <h2>swarm intelligence index for sentient memes</h2>
        <h2>$aifolio</h2>
        <p className="error-text">Failed to load token data. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="landing-page">
      <div className="gradient-banner">
        <h1 className="page-title">aifolio</h1>
        <h2>swarm intelligence index for sentient memes</h2>
        <a href="https://dexscreener.com/solana/7Wz7xym8YkFxVjekdBTuSysBy2LAQzqCeojXi9AM2M6k" target="_blank" rel="noopener noreferrer" className="aifolio-button">
          $aifolio
        </a>
        <a href="https://t.me/aifolio_tg" target="_blank" rel="noopener noreferrer" className="aifolio-button">
          cto telegram
        </a>
        <a href="https://x.com/0xAifolio" target="_blank" rel="noopener noreferrer" className="twitter-link">
          X
        </a>
      </div>
      <div className="carousels-container">
        <div className="carousel-row animate-carousel">
          <h3>Trending</h3>
          {tokensData.trending.length > 0 ? (
            <Carousel tokens={tokensData.trending} />
          ) : (
            <p>No data available for Trending.</p>
          )}
        </div>
        <div className="carousel-row animate-carousel reverse">
          <h3>artifactss</h3>
          <p className="tooltip">Memetics out of the infinite backrooms</p> {/* Memetics out of the infinite backrooms */}
          {tokensData.artifactss.length > 0 ? (
            <Carousel tokens={tokensData.artifactss} />
          ) : (
            <p>No data available for artifactss.</p>
          )}
        </div>
        <div className="carousel-row animate-carousel">
          <h3>Agents</h3>
          <p className="tooltip">AI Agents</p> {/* AI Agents */}
          {tokensData.agents.length > 0 ? (
            <Carousel tokens={tokensData.agents} />
          ) : (
            <p>No data available for Agents.</p>
          )}
        </div>
        <div className="carousel-row animate-carousel reverse">
          <h3>memes</h3>
          <p className="tooltip">memetic viruses</p> {/* memetic viruses */}
          {tokensData.memes.length > 0 ? (
            <Carousel tokens={tokensData.memes} />
          ) : (
            <p>No data available for memes.</p>
          )}
        </div>
        <div className="carousel-row animate-carousel">
          <h3>KAILs</h3>
          <p className="tooltip">Key Artificial Intelligence Leaders</p> {/* Key Artificial Intelligence Leaders */}
          {tokensData.kails.length > 0 ? (
            <Carousel tokens={tokensData.kails} />
          ) : (
            <p>No data available for KAILs.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
