import React, { useState, useEffect } from 'react';
import './Carousel.css';

const Carousel = ({ tokens }) => {
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(handleNext, 5000);
    return () => clearInterval(interval);
  }, []);

  if (!tokens || tokens.length === 0) {
    return <p>No tokens available.</p>;
  }

  const handlePrev = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? Math.max(0, tokens.length - 6) : prevIndex - 1
    );
  };

  const handleNext = () => {
    setStartIndex((prevIndex) =>
      prevIndex + 6 >= tokens.length ? 0 : prevIndex + 1
    );
  };

  const visibleTokens = tokens.slice(startIndex, startIndex + 6);

  return (
    <div className="carousel">
      <button className="carousel-button prev" onClick={handlePrev} aria-label="Previous">
        &#8249;
      </button>
      <div className="carousel-content">
        {visibleTokens.length > 0 ? (
          <div className="token-container">
            {visibleTokens.map((token, index) => (
              <a 
                key={index}
                href={token.url}
                target="_blank"
                rel="noopener noreferrer"
                className="token-panel-link"
              >
                <div className="token-panel">
                  <div className="token-header">
                  <div className="token-image-container">
                      {token.imageUrl && (
                        <img 
                          src={token.imageUrl} 
                          alt={`${token.name} logo`} 
                          className="token-image" 
                        />
                      )}
                    </div>
                    <h3>{token.name}</h3>
                  </div>
                  <div className="token-info">
                    <p>Price: ${parseFloat(token.priceUsd).toFixed(4)}</p>
                    <p>Cap: ${parseFloat(token.marketCap).toLocaleString()}</p>
                    <p>Liq: ${parseFloat(token.liquidityUsd).toLocaleString()}</p>
                    <p>Vol: ${parseFloat(token.volume).toLocaleString()}</p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        ) : (
          <p>No data available</p>
        )}
      </div>
      <button className="carousel-button next" onClick={handleNext} aria-label="Next">
        &#8250;
      </button>
    </div>
  );
};

export default Carousel;
